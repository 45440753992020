



















































































































































































































































import ErrorAlert from '@/components/common/ErrorAlert.vue';
import DatePicker from '@/components/date/DatePicker.vue';
import { ErrorManager } from '@/models/error';
import { Shipment } from '@/models/shipment';
import { IFetchManifestParams } from '@/services/shipments/service';
import moment from 'moment';
import Vue from 'vue';
import { mapActions, mapState } from 'vuex';
export default Vue.extend({
  components: { DatePicker, ErrorAlert },
  data() {
    return {
      loading: false,
      valid: false,
      showText: true,

      detailErrorMessage: '',
      errorMessage: '',

      manifestDetailErrorMessage: '',
      manifestErrorMessage: '',

      startEndValidator: '',

      payload: {
        start_date: moment().format('YYYY-MM-DD'),
        end_date: moment().format('YYYY-MM-DD'),
        warehouse: 'AMS'
      } as IFetchManifestParams,

      warehouseList: [
        {
          name: 'Air Meet Sea',
          value: 'AMS'
        },
        {
          name: 'Pelipak',
          value: 'PK'
        }
      ],

      search: '',
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,

      headers: [
        {
          text: '',
          sortable: false,
          align: 'start',
          width: '20px',
          value: 'data-table-space'
        },
        {
          text: 'Name',
          width: '200px',
          value: 'name'
        },
        {
          text: 'Prefix',
          value: 'prefix'
        },
        {
          text: 'Tracking Number',
          value: 'tracking_number'
        },
        {
          text: 'Package Description',
          value: 'description'
        },
        {
          text: 'Trn',
          value: 'trn'
        },
        {
          text: 'Parish',
          value: 'parish'
        },
        {
          text: 'Weight',
          value: 'weight'
        },
        {
          text: 'Pickup Price',
          value: 'price'
        },
        {
          text: 'Shipper',
          value: 'shipper'
        },
        {
          text: 'House Number',
          value: 'house_number'
        },
        {
          text: 'Type',
          value: 'type'
        }
      ]
    };
  },
  computed: {
    ...mapState('shipment', ['manifest'])
  },
  methods: {
    ...mapActions({
      fetchManifest: 'shipment/fetchManifest',
      updateManifest: 'shipment/updateManifest',
      exportManifest: 'shipment/exportManifest'
    }),

    checkDateValidator() {
      this.startEndValidator =
        this.payload.end_date < this.payload.start_date
          ? 'Date cannot be before start'
          : '';
    },

    updatePackageManifest(item: Shipment) {
      this.loading = true;

      if (!item.is_user_assigned) {
        delete item.trn;
      }

      this.updateManifest(item)
        .catch((error: any) => {
          this.manifestErrorMessage = ErrorManager.extractApiError(error);

          if (error.response && error.response.data.detail) {
            this.manifestDetailErrorMessage = error.response.data.detail;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    genManifest() {
      this.loading = true;
      this.fetchManifest(this.payload)
        .catch((error: any) => {
          this.errorMessage = ErrorManager.extractApiError(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    async downloadLinks() {
      this.manifest.forEach((shipmentPackage: Shipment) => {
        // Usage example
        for (let invoice of shipmentPackage.invoices) {
          const fileURL = invoice.image;
          const fileName = shipmentPackage.description || 'file';
          this.downloadFile(fileURL, fileName);
        }
      });
    },

    downloadFile(url: string, filename: string) {
      fetch(url)
        .then((response) => response.blob())
        .then((blob) => {
          const fileURL = URL.createObjectURL(blob);

          const a = document.createElement('a');
          a.href = fileURL;
          a.download = filename;

          document.body.appendChild(a);
          a.click();

          document.body.removeChild(a);
          URL.revokeObjectURL(fileURL);
        });
    },

    async downloadManifest() {
      this.loading = true;
      await this.downloadLinks();
      window.location.href = `http://127.0.0.1:8000/api/v1/package/manifest/export/?start-date=${this.payload.start_date}T00:00&end-date=${this.payload.end_date}T23:59&warehouse=${this.payload.warehouse}`;
      this.loading = false;
    }
  }
});
